export default {
  blue: {
    50: '#EEEEFF',
    100: '#EEEEFF',
    200: '#e5e8ff',
    300: '#b5bcff',
    400: '#858ff9',
    500: '#5865F6',
    600: '#37419B',
    700: '#37419B',
    800: '#0074bf',
  },
  green: {
    50: '#E6F5F0',
    100: '#64DCC3',
    200: '#34f3d3',
    300: '#22d9b9',
    400: '#14aa90',
    500: '#078571',
    600: '#056455',
    700: '#00493e',
    800: '#001a15',
    900: '#001a15',
  },
  black: {
    100: '#0E1314',
    200: '#1C2629',
    300: '#374B52',
    400: '#4B5D63',
    500: '#636363',
    600: '#879397',
    700: '#AFB7BA',
    800: '#C3C9CB',
    900: '#424242',
  },
  white: {
    100: '#D7DBDC',
    200: '#EBEDEE',
    300: '#F0F1F1',
    400: '#F6F7F7',
    500: '#E0E0E0',
  },
  purple: {
    1: '#828cfa',
  },
};
